<template>
  <div class="store-introduce">
    <div class="cell">
      <div class="title">商店介绍</div>
      <div style="font-size: 12px; color: #a4a7ad; margin-top: 10px">
        查看您的商店状态，更新商店档案
      </div>
      <div class="tab">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本资料" name="first">
            <div class="content">
              <div class="left">
                <div class="img">
                  <el-image style="
                      left: 0;
                      right: 0;
                      bottom: 0;
                      top: 0;
                      position: absolute;
                    " :src="fm" fit="cover">
                    <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                      style="width: 50px; height: 50px">
                      <path
                        d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                    </svg></el-image>
                  <div class="photo">
                    <el-avatar style="width: 56px; height: 56px" :src="circleUrl"></el-avatar>
                    <div class="edit-btn" @click="dialogVisible = true">
                      编辑
                    </div>
                  </div>
                  <div class="info">
                    <div style="font-weight: 600">{{ shopInfo.shop_name }}</div>
                    <div style="margin-top: 6px; font-size: 12px">
                      {{ shopInfo.create_time }}加入
                    </div>
                  </div>
                  <!-- <div class="bottom">修改商店封面</div> -->
                  <el-upload class="avatar-uploader" :action="apiUrl + 'api/upload/files'" name="imagelist"
                    :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload" :accept="accept">
                    <div class="bottoms">修改商店封面</div>
                  </el-upload>
                </div>
                <div class="text">
                  <div>
                    <i class="el-icon-monitor"></i>
                    商店首页
                  </div>
                  <div style="color: #ee4d2d; cursor: pointer" @click="toStore">
                    查看
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
                <div class="text">
                  <div>
                    <i class="el-icon-present"></i>
                    商品
                  </div>
                  <div style="color: #ee4d2d; cursor: pointer" @click="toMyGoods">
                    {{ shopInfo.goods_num ? shopInfo.goods_num : "0" }}
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
                <div class="text" style="border-radius: 0 0 6px 6px">
                  <div>
                    <i class="el-icon-star-off"></i>
                    商店评价
                  </div>
                  <div style="color: #ee4d2d">
                    {{ shopInfo.comment_num ? shopInfo.comment_num : "0" }}
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="label">商店名称</div>
                <el-input style="width: 100%" maxlength="35" show-word-limit v-model="name" placeholder="商店名称"></el-input>
                <div style="color: #ee4d2d; font-size: 12px" v-if="name.length < 5 && name != ''">
                  您的商店名称必须介于5-35个字符.
                </div>
                <div class="label" style="margin-top: 20px">商品图片</div>
                <UploadFiles2 :imageUrl="imageUrl" :ids="imageUrlID" :imgLength="5">
                </UploadFiles2>
                <div class="label" style="margin-top: 20px">商店介绍</div>
                <el-input style="width: 100%" maxlength="500" show-word-limit v-model="introduce" rows="8" type="textarea"
                  placeholder="在这里输入您商店的描述或资料"></el-input>
                <el-button size="medium" type="primary" style="margin-top: 40px" @click="handleStorage">储存<i v-if="show"
                    class="el-icon-loading"></i>
                </el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <avatar-cropper :dialogVisible.sync="dialogVisible" @closeAvatarDialog="closeAvatarDialog"></avatar-cropper>
  </div>
</template>
<script>
import UploadFiles2 from "@/components/UploadFiles2";
import { shopInfo, shopInfoEdit } from "@/api/shop.js";
import { uploadFiles } from "@/api/common.js";
import avatarCropper from "@/components/avatarCropper";
import env from "@/settings/env.js";
import { getSAuth, getSchool } from "@/utils/auth.js";
export default {
  components: {
    UploadFiles2,
    // myUpload,

    avatarCropper,
  },
  data() {
    return {
      apiUrl: env.apiUrlPrefix,
      baseUrl: env.fileUrlPrefix,
      activeName: "first",
      fma: require("@/assets/img/shop/封面.webp"),
      fm: "",
      fms: "",
      fmID: "",
      imageUrl: [],
      imageUrlID: [],
      shopInfo: "",
      name: "",
      introduce: "",
      circleUrl: "",
      circleUrls: "",
      circleUrlID: "",
      dialogVisible: false,
      accept: ".jpg,.jpeg,.png",
      headers: {
        authorization: getSAuth(),
        school: getSchool(),
      },
      show: false,
    };
  },
  created() {
    this.getShopInfo();
  },
  methods: {
    handleClick() { },
    getShopInfo() {
      shopInfo().then((res) => {
        if (res.code == 1) {
          this.shopInfo = res.data;
          if (res.data.shop_avatar) {
            this.circleUrl = res.data.shop_avatar;
            this.circleUrls = res.data.shop_avatar;
            this.circleUrlID = res.data.attachment_id;
          }

          if (res.data.back_image) {
            this.fm = res.data.back_image;
            this.fms = res.data.back_image;
            this.fmID = res.data.attachment_id_back;
          } else {
            this.fm = this.fma;
          }

          this.name = res.data.shop_name;
          this.introduce = res.data.bio;
          if (res.data.carousel != "") {
            this.imageUrl = res.data.carousel.split(",");
            this.imageUrlID = res.data.attachment_id_carousel.split(",");
          }
        }
      });
    },
    handleStorage() {
      if (this.name.length < 5) {
        this.$message.error("您的商店名称必须介于5-35个字符");
        return false;
      }
      this.show = true;
      let _this = this;
      setTimeout(function () {
        shopInfoEdit({
          name: _this.name,
          carousel: _this.imageUrlID.join(","),
          bio: _this.introduce,
          avatar: _this.circleUrlID,
          image: _this.fmID,
        }).then((res) => {
          if (res.code == 1) {
            _this.show = false;
            _this.$message.success(res.message);
          }
        });
      }, 1000);
    },
    closeAvatarDialog(data) {
      let formData = new FormData();
      formData.append("imagelist", data.data, data.fileName);
      uploadFiles(formData).then((res) => {
        if (res.code == 1) {
          this.circleUrl = res.data.url;
          this.circleUrls = res.data.short_url;
          this.circleUrlID = res.data.id;
          this.$forceUpdate();
        }
      });
      this.dialogVisible = false;
    },
    // 封面上传成功后
    handleAvatarSuccess(res) {
      this.fm = "";
      if (res.code == 1) {
        this.fm = res.data.url;
        this.fms = res.data.short_url;
        this.fmID = res.data.id;
      }
    },
    // 封面上传前,
    beforeAvatarUpload() { },
    toStore() {
      this.$router.push({
        path: "storeHome",
        query: {
          shop_id: this.shopInfo.id,
        },
      });
    },
    toMyGoods() {
      this.$router.push("myGoods");
      this.$store.state.path = "myGoods";
    },
  },
};
</script>
<style lang="less" >
.store-introduce {
  //   padding: 20px;
  //   padding-top: 10px;
  min-width: 1500px;

  .cell {
    padding: 30px;
    background: #fff;

    .title {
      font-size: 18px;
    }

    .tab {
      margin-top: 20px;

      .el-tabs__nav-wrap::after {
        height: 1px;
      }

      .el-tabs__item.is-active {
        font-weight: 600;
      }

      .el-tabs__nav {
        height: 50px;
      }

      .content {
        display: flex;

        .left {
          width: 400px;

          .img {
            border-radius: 10px 10px 0 0;
            overflow: hidden;
            width: 400px;
            height: 200px;
            position: relative;

            .photo {
              position: absolute;
              z-index: 1;
              top: 60px;
              left: 60px;
              height: 56px;
              width: 56px;
              border-radius: 50%;
              overflow: hidden;

              .edit-btn {
                position: absolute;
                bottom: 0;
                font-size: 12px;
                left: 0;
                right: 0;
                text-align: center;
                color: #fff;
                line-height: 18px;
                background: rgba(0, 0, 0, 0.5);
                cursor: pointer;
                border-radius: 0 0 31px 31px;
              }
            }

            .info {
              position: absolute;
              color: #fff;
              font-size: 14px;
              z-index: 1;
              left: 140px;
              top: 60px;
            }

            .bottoms {
              position: absolute;
              z-index: 1;
              background: rgba(0, 0, 0, 0.4);
              left: 0;
              right: 0;
              bottom: 0;
              color: #fff;
              font-size: 12px;
              line-height: 26px;
              text-align: center;
              cursor: pointer;
            }
          }

          .text {
            width: 100%;
            height: 46px;
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: space-between;
            border: 1px solid #e5e5e5;
            border-top: none;
            padding: 20px;
            color: #666;
          }
        }

        .right {
          width: 100%;
          margin-left: 30px;

          .label {
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>