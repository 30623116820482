<template>
  <div style="display: flex" class="UploadFiles2">
    <div v-if="imageUrl.length != 0">
      <div style="position: relative; display: inline-block; margin-right: 10px" class="img-box"
        v-for="(item, index) in imageUrl" :key="index">
        <el-image fit="contain" class="avatar" :src="item">
          <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
            style="width: 50px; height: 50px">
            <path
              d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
          </svg>
        </el-image>
        <i class="el-icon-error" style="
            position: absolute;
            right: 8px;
            top: 8px;
            z-index: 1;
            font-size: 20px;
            color: #ee4d2d;
          " @click="delImg(index)"></i>
      </div>
    </div>
    <el-upload v-if="imageUrl.length < imgLength" class="avatar-uploader" :action="action" :show-file-list="false"
      :headers="headers" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="imagelist"
      :accept="accept">
      <div slot="trigger" class="asd">
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <div style="
            position: absolute;
            top: 100px;
            font-size: 12px;
            left: 0;
            right: 0;
            text-align: center;
          ">
          添加图片({{ imageUrl.length }}/{{ imgLength }})
        </div>
      </div>
    </el-upload>
  </div>
</template>
<script>
import { getSAuth, getSchool } from "@/utils/auth.js";
import env from "@/settings/env.js";
import { deleteFile } from "@/api/common.js";
export default {
  name: "UploadFiles",
  data() {
    return {
      headers: {
        Authorization: getSAuth(),
        school: getSchool(),
      },
      // imageUrl: [],
      action: env.apiUrlPrefix + "api/upload/files",
    };
  },
  props: {
    imgLength: { type: Number, default: 1 },
    accept: { type: String, default: ".jpg,.jpeg,.png" },
    imageUrl: { type: Array },
    ids: { type: Array },
  },
  mounted() {
    if (this.$props.accept == ".mp4,.avi") {
      this.action = env.apiUrlPrefix + "api/upload/video";
    }
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    beforeAvatarUpload(file) {
      console.log(file);
      //   return false;
    },
    handleAvatarSuccess(res) {
      if (res.code == 1) {
        // this.$message.success("上传成功");
        if (this.$props.accept == ".mp4,.avi") {
          this.$props.imageUrl.push(res.data.video_img);
          this.$props.video.push(res.data.url);
        } else {
          this.$props.imageUrl.push(res.data.url);
          this.$props.ids.push(res.data.id);
        }
      } else {
        this.$message.error(res.message);
      }
    },
    delImg(index) {
      if (this.$props.accept == ".mp4,.avi") {
        this.$props.video.splice(index, 1);
      }
      // deleteFile({
      //   file_id: this.$props.ids[index],
      // }).then((res) => {

      //   if (res.code == 1) {
      this.$props.imageUrl.splice(index, 1);
      this.$props.ids.splice(index, 1);
      //   }
      // });
    },
  },
};
</script>
<style lang="less">
.UploadFiles2 {
  .avatar-uploader {
    position: relative;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #ee4d2d;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 186px;
    height: 170px;
    line-height: 166px;
    text-align: center;
  }

  // .avatar-uploader-icon:hover {
  //   color: #ee4d2d;
  // }
  .asd {
    color: #b7b7b7;
  }

  .asd:hover {
    color: #ee4d2d;
  }

  .asd:hover .avatar-uploader-icon {
    color: #ee4d2d;
  }

  .avatar {
    width: 186px;
    height: 170px;
    display: block;
    border-radius: 6px;
    border: 1px dashed #d9d9d9;
    padding: 22px;

    .el-icon-error {
      display: none;
    }
  }

  .img-box:hover:hover .avatar {
    border-color: #ee4d2d;
  }

  .img-box:hover .el-icon-error {
    display: block;
  }
}
</style>